import React from "react";

type Props = {
  iconSrc: string;
  title: string;
  text: string;
};

export default function HowItWorksItem({ iconSrc, title, text }: Props) {
  return (
    <div className="text-center flex-1">
      <div className="flex justify-center">
        <img className="w-12 h-12" src={iconSrc} alt="How it works icon" />
      </div>
      <p className="text-white text-xl font-semibold mt-4">{title}</p>
      <p className="text-white text-base">{text}</p>
    </div>
  );
}
