import React from "react";
import HowItWorksItem from "./HowItWorksItem";
import howItWorksIcon1 from "../images/works1.png";
import howItWorksIcon2 from "../images/works2.png";
import howItWorksIcon3 from "../images/works3.png";

export default function HowItWorksHeroSection() {
  return (
    <div className="bg-gradient-to-r from-backgroundGradientStart to-backgroundGradientEnd">
      <div className="p-8 md:py-24 text-center mx-auto max-w-7xl">
        <div className="md:px-32">
          <h4 className="text-white text-base font-semibold">How it works</h4>
          <h2 className="text-white text-4xl md:text-5xl font-semibold mt-4">
            Easy to use, more transparent for your holders
          </h2>
        </div>
        <div className="flex flex-col md:flex-row mt-20 px-4 gap-8 mb-8">
          <HowItWorksItem
            iconSrc={howItWorksIcon1}
            title="Create products you want to give away"
            text="Products can later be added to rewards."
          />
          <HowItWorksItem
            iconSrc={howItWorksIcon2}
            title="Set up a reward"
            text="Create a nice reward that you can use to push your NFT project. Holders can utilize their NFT to participate in a raffle or claim the reward."
          />
          <HowItWorksItem
            iconSrc={howItWorksIcon3}
            title="Push your NFT project"
            text="Use your rewards for marketing or to treat your NFT holders. Thanks to nftclaimer.xyz all rewards are fully transparent, even if NFT is traded in secondary market."
          />
        </div>
      </div>
    </div>
  );
}
