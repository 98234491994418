import React from "react";
import Footer from "../components/Footer";
import HowItWorksHeroSection from "../components/HowItWorksHeroSection";
import HowItWorksSteps from "../components/HowItWorksSteps";
import MainMenu from "../components/MainMenu";
import howItWorks from "../images/how_it_works.png";

export default function HowItWorksPage() {
  return (
    <div className="bg-gradient-to-r from-backgroundGradientStart to-backgroundGradientEnd">
      <MainMenu />
      <HowItWorksHeroSection />

      <div className="bg-white p-8 md:py-16">
        <img
          className="py-16 mx-auto max-w-2xl w-full"
          src={howItWorks}
          alt="How it works image"
        />
        <HowItWorksSteps />
      </div>

      <Footer />
    </div>
  );
}
