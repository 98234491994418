import React from "react";
import HowItWorksStepItem from "./HowItWorksStepItem";
import iconStep1 from "../images/works_step1.png";
import iconStep2 from "../images/works_step2.png";
import iconStep3 from "../images/works_step3.png";
import iconStep4 from "../images/works_step4.png";

export default function HowItWorksSteps() {
  return (
    <div className="mx-auto max-w-2xl">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <HowItWorksStepItem
          iconSrc={iconStep1}
          title="Connect wallet"
          text="To be able to claim a reward users need to connect their wallet. We will check automatically if the user is the current holder of this NFT and is allowed to claim the reward."
        />
        <HowItWorksStepItem
          iconSrc={iconStep2}
          title="Easily check if you have won"
          text="Entering the token id is all a NFT holders need to do to check if one of their NFTs has won a reward."
        />
        <HowItWorksStepItem
          iconSrc={iconStep3}
          title="Be fully transparent"
          text="If you give out rewards NFTs can still be traded in the secondary market. Give your community a tool to check which rewards have been claimed or not."
        />
        <HowItWorksStepItem
          iconSrc={iconStep4}
          title="Set incentives for the future"
          text="Plan upcoming rewards to increase the value of your NFTs."
        />
      </div>
    </div>
  );
}
