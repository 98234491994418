import React from "react";

type Props = {
  iconSrc: string;
  title: string;
  text: string;
};

export default function HowItWorksStepItem({ iconSrc, title, text }: Props) {
  return (
    <div className="bg-stepCellBackground p-6">
      <img className="w-12 h-12" src={iconSrc} alt="Step Icon" />
      <p className="text-xl mt-16">{title}</p>
      <p className="text-base mt-2">{text}</p>
    </div>
  );
}
